import { FC } from 'react';
import { Property } from 'csstype';
import { ELiveTrackingDriverVehicle } from 'types/api';
import { calcZRotate } from './DriverMarkerItem/helpers';
import { ICON_BY_VEHICLE_TYPE } from './config';

interface ILiveDriverMarkerIconProps {
	fillColor?: Property.Color;
	rotateAngle: number;
	driverMarkerSize: number;
	vehicleType: ELiveTrackingDriverVehicle;
}

const LiveDriverMarkerIcon: FC<ILiveDriverMarkerIconProps> = ({
	fillColor,
	rotateAngle,
	driverMarkerSize,
	vehicleType,
}) => {
	// ! render
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={driverMarkerSize}
			height={driverMarkerSize}
			viewBox='0 0 32 32'
			fillOpacity={1}
			style={{
				transform: `rotateZ(${calcZRotate(rotateAngle)}deg)`,
			}}
		>
			<circle
				cx='16'
				cy='16'
				r='15'
				fill={fillColor}
			/>
			<path
				d='M15.8793 13.7071C16.2158 13.7485 16.6089 13.7958 16.999 13.8432C17.529 13.9082 18.0025 14.1212 18.4433 14.408C18.5832 14.4997 18.7142 14.6091 18.7708 14.7718C18.8036 14.8723 18.8304 14.9847 18.8274 15.0912C18.8214 15.3011 18.8006 15.5141 18.7678 15.7241C18.7053 16.1203 18.6398 16.5196 18.5594 16.9129C18.5147 17.1288 18.4462 17.3417 18.3807 17.5517C18.3509 17.6523 18.3003 17.667 18.1991 17.6227C17.8953 17.4926 17.5767 17.4216 17.2551 17.3565C16.7995 17.2648 16.3379 17.2205 15.8734 17.2234C15.5309 17.2264 15.1855 17.2471 14.846 17.2915C14.5155 17.3329 14.1849 17.4098 13.8603 17.4837C13.7174 17.5162 13.5804 17.5695 13.4434 17.6256C13.3571 17.6611 13.2975 17.6463 13.2707 17.5635C13.2141 17.3831 13.1546 17.1998 13.1129 17.0135C13.0503 16.7236 12.9938 16.4338 12.9461 16.141C12.8985 15.8335 12.8627 15.5259 12.824 15.2183C12.7704 14.8161 12.961 14.5411 13.2975 14.3489C13.5923 14.1803 13.8871 14.0147 14.2177 13.9378C14.4857 13.8757 14.7537 13.8284 15.0247 13.7899C15.2897 13.7515 15.5547 13.7367 15.8793 13.7071Z'
				fill={fillColor}
			/>
			<path
				d='M18.3236 22.427C18.3355 22.4717 18.3563 22.5165 18.3563 22.5612C18.377 23.1812 18.4008 23.8013 18.4127 24.4214C18.4186 24.6957 18.3236 24.9461 18.0713 25.0772C17.8932 25.1726 17.6943 25.2412 17.4984 25.2829C17.2312 25.3396 16.9581 25.3694 16.685 25.3873C16.3139 25.4111 15.9399 25.426 15.5659 25.4201C15.2097 25.4141 14.8534 25.3873 14.5002 25.3455C14.1885 25.3068 13.8738 25.265 13.5948 25.107C13.3751 24.9818 13.2297 24.803 13.2297 24.5406C13.2297 24.0368 13.2415 23.533 13.2475 23.0322C13.2505 22.8951 13.2564 22.7579 13.2623 22.6178C13.2623 22.588 13.2653 22.5552 13.2742 22.5254C13.2861 22.4956 13.3069 22.4688 13.3247 22.4389C13.3544 22.4598 13.3929 22.4717 13.4108 22.5016C13.6482 22.8712 14.0134 23.0024 14.42 23.059C14.9989 23.1395 15.5807 23.1663 16.1625 23.1425C16.5425 23.1246 16.9225 23.1037 17.2995 23.0471C17.6171 22.9994 17.9199 22.8891 18.1307 22.6178C18.1841 22.5493 18.2345 22.4837 18.288 22.4151C18.2999 22.4211 18.3117 22.424 18.3236 22.427Z'
				fill={fillColor}
			/>

			{ICON_BY_VEHICLE_TYPE[vehicleType]}
		</svg>
	);
};

export default LiveDriverMarkerIcon;
