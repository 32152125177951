import { renderToString } from 'react-dom/server';
import { ELiveTrackingDeliveryStatus, ILiveTrackingDriver, TLiveTrackingStatusToColor } from 'types/api';
import { Nullable } from 'types/common';
import { DriverMarkerIcon } from 'pages/LiveTracking/liveTrackComponents/LiveTrackingMap/components/DriversMarkers/DriverMarkerItem/DriverMarkerIcon';

export const getIconAngle = (
	newPosition: google.maps.LatLngLiteral,
	previousPosition?: Nullable<google.maps.LatLng>
): number => {
	if (!previousPosition) return 0;

	const angle = google.maps.geometry.spherical.computeHeading(previousPosition, newPosition);
	const newAngle = Math.round(angle - 90);

	return newAngle;
};

export const generateDriverIconElement = (
	driver: ILiveTrackingDriver,
	driverDeliveryStatusFilters: ELiveTrackingDeliveryStatus[],
	driverFiltersSettings: TLiveTrackingStatusToColor,
	previousPosition?: Nullable<google.maps.LatLng>,
	markerSize: number = 25
) => {
	const imageElement = new Image(markerSize, markerSize);

	const componentString = window.btoa(
		renderToString(
			<DriverMarkerIcon
				rotateAngle={getIconAngle(driver.location, previousPosition)}
				driverMarkerSize={markerSize}
				driverInfoItem={driver}
				driverDeliveryStatusFilters={driverDeliveryStatusFilters}
				driverFiltersSettings={driverFiltersSettings}
			/>
		)
	);
	const iconUrl = `data:image/svg+xml;charset=UTF-8;base64,${componentString}`;

	imageElement.src = iconUrl;

	return imageElement;
};
