import { FC, useMemo } from 'react';
import { getDriverIconColors } from './helpers';
import { ELiveTrackingDeliveryStatus, ILiveTrackingDriver, TLiveTrackingStatusToColor } from 'types/api';
import LiveDriverMarkerIcon from '../LiveDriverMarkerIcon';
import MultipleOrdersDriverIcon from './MultipleOrdersDriverIcon';

interface IDriverMarkerIconProps {
	driverMarkerSize: number;
	driverInfoItem: ILiveTrackingDriver;
	rotateAngle: number;
	driverDeliveryStatusFilters: ELiveTrackingDeliveryStatus[];
	driverFiltersSettings: TLiveTrackingStatusToColor;
}

export const DriverMarkerIcon: FC<IDriverMarkerIconProps> = ({
	driverInfoItem,
	rotateAngle,
	driverMarkerSize,
	driverDeliveryStatusFilters,
	driverFiltersSettings,
}) => {
	const { color, ordersColors } = useMemo(
		() => getDriverIconColors(driverInfoItem, driverDeliveryStatusFilters, driverFiltersSettings),
		[driverDeliveryStatusFilters, driverFiltersSettings, driverInfoItem]
	);

	if (driverInfoItem.orders?.length && driverInfoItem.orders?.length > 1) {
		return (
			<MultipleOrdersDriverIcon
				rotateAngle={rotateAngle}
				driverColor={color}
				vehicleType={driverInfoItem.vehicle}
				stripesColors={ordersColors}
			/>
		);
	}

	return (
		<LiveDriverMarkerIcon
			vehicleType={driverInfoItem.vehicle}
			fillColor={color}
			rotateAngle={rotateAngle}
			driverMarkerSize={driverMarkerSize}
		/>
	);
};
