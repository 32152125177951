import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import {
	ELiveTrackingDeliveryStatus,
	ELiveTrackingDriverCapacityStatus,
	ELiveTrackingDriverVehicle,
	IArea,
	IBranch,
	IBranchesLocationData,
	ILiveTrackingDriver,
} from 'types/api';
import { Nullable } from 'types/common';
import { filterDrivers, getEnabledFiltersFromSettings } from 'pages/LiveTracking/helpers';
import { IAssignOrderToDriverModalHandle } from 'pages/LiveTracking/liveTrackComponents/AssignOrderToDriverModal/types';
import {
	DEFAULT_BRANCH_MARKER_SIZE,
	DEFAULT_DRIVER_MARKER_SIZE,
} from 'pages/LiveTracking/liveTrackComponents/LiveTrackingMap/config';
import { useCachedBranchList } from 'pages/LiveTracking/liveTrackComponents/LiveTrackingMap/useCachedBranchList';
import { useDriverLiveTrackSocket } from '../useDriverLiveTrackSocket';
import { Marker } from '@react-google-maps/api';

export interface ILiveTrackingControllerData extends ReturnType<typeof useLiveTrackingCtrl> {}

export const useLiveTrackingCtrl = () => {
	const navigate = useNavigate();

	const { driversData, socketLoading, driversMetrics, driverFiltersSettings, changeTimeInterval } =
		useDriverLiveTrackSocket();
	const { branchMap, loadMoreBranches } = useCachedBranchList();

	// ! states
	// drivers
	const [selectedDriver, setSelectedDriver] = useState<Nullable<ILiveTrackingDriver>>(null);

	const [showDriversClusters, setShowDriversClusters] = useState(true);
	const [showDriverMarkers, setShowDriverMarkers] = useState(true);
	const [driverCapacityStatusFilters, setDriverCapacityStatusFilters] = useState<ELiveTrackingDriverCapacityStatus[]>(
		[]
	);
	const [driverDeliveryStatusFilters, setDriverDeliveryStatusFilters] = useState<ELiveTrackingDeliveryStatus[]>([]);
	const [driverVehicleFilters, setDriverVehicleFilters] = useState<ELiveTrackingDriverVehicle[]>([]);

	// branches
	const [showBranchesPins, setShowBranchesPins] = useState(false);
	const [showBranchesClusters, setShowBranchesClusters] = useState(true);

	// map
	const [googleMap, setGoogleMap] = useState<Nullable<google.maps.Map>>(null);

	// area
	const [selectedArea, setSelectedArea] = useState<Nullable<IArea>>(null);

	// Markers
	const driverMarkerMapRef = useRef<Record<ILiveTrackingDriver['id'], Nullable<Marker>>>({});
	const storeMarkerMapRef = useRef<Record<IBranch['id'], Nullable<Marker>>>({});

	const assignOrderRef = useRef<IAssignOrderToDriverModalHandle>(null);

	// Marker Sizes
	const [driverMarkerSize, setDriverMarkerSize] = useState(DEFAULT_DRIVER_MARKER_SIZE);
	const [branchMarkerSize, setBranchMarkerSize] = useState(DEFAULT_BRANCH_MARKER_SIZE);

	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! memos
	// Filtered drivers without frontend changes ('color' field).
	const driversList = useMemo<ILiveTrackingDriver[]>(() => {
		if (!showDriverMarkers) return [];

		return filterDrivers({ driversData, driverVehicleFilters, driverCapacityStatusFilters });
	}, [driversData, driverVehicleFilters, driverCapacityStatusFilters, showDriverMarkers]);

	// ! handlers
	const onCloseDriverInfo = () => {
		setSelectedDriver(null);
	};
	const onClickDriverMarker = (driver: ILiveTrackingDriver) => {
		setSelectedDriver(null);
		setSelectedDriver(driver);
	};

	// * MAP
	const onAreaChange = useCallback(
		(area?: IArea) => {
			setSelectedArea(area || null);

			const newCenter = area?.center;

			if (!newCenter || !googleMap) return;

			googleMap.setCenter(newCenter);
			googleMap.setZoom(13);
		},
		[googleMap]
	);

	// * SEARCH
	const onDriverSearch = useCallback(
		(driverId: Nullable<number>) => {
			if (!driverId || !googleMap) return;

			const candidate = driversList.find(({ id }) => id === driverId);
			if (!candidate) return;

			googleMap.setCenter(candidate.location);
			googleMap.setZoom(16);
		},
		[driversList, googleMap]
	);

	// * FILTERS
	const onOrderCapacityStatusFiltersChange = useCallback(
		(res: ELiveTrackingDriverCapacityStatus[]) => setDriverCapacityStatusFilters(res),
		[]
	);

	const onOrderDeliveryStatusFiltersChange = useCallback(
		(res: ELiveTrackingDeliveryStatus[]) => setDriverDeliveryStatusFilters(res),
		[]
	);

	const onVehicleTypeFiltersChange = useCallback(
		(res: ELiveTrackingDriverVehicle[]) => setDriverVehicleFilters(res),
		[]
	);

	// * ORDER
	const onMoreOrderDetails = (orderId: number) => {
		navigate(generatePath(ABSOLUTE_ROUTES.ORDER_DETAILS, { countryIsoTwoCode, orderId }));
	};

	// ! effects
	// drivers
	useEffect(() => {
		// set defaults if filteredDrivers is empty
		if (!driversList.length) {
			// remove selected driver if there are no drivers
			setSelectedDriver(null);
			return;
		}

		// check if selected driver exists in updated filteredDrivers
		setSelectedDriver((prevSelected) => {
			if (!prevSelected) return null;

			const candidate = driversList.find((driverItem) => driverItem.id === prevSelected.id);
			if (!candidate) return null;

			return candidate;
		});
	}, [driversList]);

	// enable filters
	useEffect(() => {
		if (!driverFiltersSettings) return;

		const enabledVehicle = getEnabledFiltersFromSettings(driverFiltersSettings.vehicle);
		setDriverVehicleFilters(enabledVehicle);

		const enabledCapacityStatus = getEnabledFiltersFromSettings(driverFiltersSettings.capacity_status);
		setDriverCapacityStatusFilters(enabledCapacityStatus);

		const enabledDeliveryStatus = getEnabledFiltersFromSettings(driverFiltersSettings.delivery_status);
		setDriverDeliveryStatusFilters(enabledDeliveryStatus);
	}, [driverFiltersSettings]);

	const filteredBranches = useMemo<IBranchesLocationData[]>(() => {
		const branchList = Object.values(branchMap);

		if (selectedArea?.polygon) {
			const selectedAreaPolygon = new google.maps.Polygon({
				paths: selectedArea.polygon.coordinates,
			});

			return branchList.filter((branch) =>
				google.maps.geometry.poly.containsLocation(branch.location, selectedAreaPolygon)
			);
		}

		return branchList;
	}, [branchMap, selectedArea]);

	// ! render
	return {
		driverMarkerMapRef,
		storeMarkerMapRef,
		driversData,
		socketLoading,
		driversMetrics,
		driverFiltersSettings,
		changeTimeInterval,
		selectedDriver,
		showDriversClusters,
		setShowDriversClusters,
		setShowDriverMarkers,
		showBranchesPins,
		setShowBranchesPins,
		showBranchesClusters,
		setShowBranchesClusters,

		selectedArea,
		//
		onAreaChange,
		onDriverSearch,
		onOrderCapacityStatusFiltersChange,
		onOrderDeliveryStatusFiltersChange,
		onVehicleTypeFiltersChange,
		onMoreOrderDetails,

		googleMap,
		setGoogleMap,

		showDriverMarkers,
		onClickDriverMarker,
		onCloseDriverInfo,
		driversList,
		driverVehicleFilters,
		driverCapacityStatusFilters,
		driverDeliveryStatusFilters,

		driverMapRef: driverMarkerMapRef,

		filteredBranches,
		loadMoreBranches: (googleMap: google.maps.Map) => {
			if (!showBranchesPins) return;

			loadMoreBranches(googleMap);
		},

		// Marker Sizes
		driverMarkerSize,
		setDriverMarkerSize,
		branchMarkerSize,
		setBranchMarkerSize,

		//
		assignOrderRef,
	};
};

const LiveTrackingContext = createContext<ILiveTrackingControllerData>({} as ILiveTrackingControllerData);

const useLiveTracking = () => {
	return useContext(LiveTrackingContext);
};

const LiveTrackingProvider = ({ children }: PropsWithChildren) => {
	const contextData = useLiveTrackingCtrl();

	// ! render
	return <LiveTrackingContext.Provider value={contextData}>{children}</LiveTrackingContext.Provider>;
};

export { LiveTrackingProvider, useLiveTracking };
