import { FC, useEffect } from 'react';
import { IMapEventManagerProps } from './types';
import { useMap } from '@vis.gl/react-google-maps';

export const MapEventManager: FC<IMapEventManagerProps> = ({ ...props }) => {
	const map = useMap();
	const gme = google.maps.event;

	useEffect(() => {
		if (!map) return;

		if (props.onClick) {
			map.addListener('click', props.onClick);
		}
		if (props.onRightClick) {
			map.addListener('rightclick', props.onRightClick);
		}

		return () => {
			gme.clearInstanceListeners(map);
		};
	}, [gme, map, props.onClick, props.onRightClick]);

	return <></>;
};
