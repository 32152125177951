import { Property } from 'csstype';
import { ELiveTrackingDeliveryStatus, ILiveTrackingDriver, TLiveTrackingStatusToColor } from 'types/api';

export const calcZRotate = (rotateAngle: number) => rotateAngle + 90;

export const getDriverIconColors = (
	driver: ILiveTrackingDriver,
	driverDeliveryStatusFilters: ELiveTrackingDeliveryStatus[],
	statusToColor: TLiveTrackingStatusToColor
): {
	color: Property.Color;
	ordersColors: Property.Color[];
} => {
	let ordersColors: Property.Color[] = [];
	let color: Property.Color = statusToColor[driver.capacity_status];

	const orders = driver.orders || [];

	// multiple orders - driver's capacity status color, and we have add stripes
	ordersColors = orders.map<Property.Color>(({ delivery_status }) => {
		if (driverDeliveryStatusFilters.includes(delivery_status)) {
			return statusToColor[delivery_status];
		}
		return color;
	});

	// one order - driver order status color, if filter is selected
	if (driver.orders?.length === 1) {
		color = statusToColor[driver.orders[0].delivery_status];
	}

	return {
		color,
		ordersColors,
	};
};
