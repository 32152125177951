import { FC, useMemo } from 'react';
import { Polygon as VisPolygon } from 'utils/Map';
import { IAreaPolygonProps } from './types';
import { Polygon } from '@react-google-maps/api';
import { useMap } from '@vis.gl/react-google-maps';

const AreaPolygon: FC<IAreaPolygonProps> = ({
	area,
	clickable,
	isHovered,
	isIntersected,
	isSelected,
	isVisible,
	...restProps
}) => {
	const visMap = useMap();

	// ! memos
	const colorOptions = useMemo(() => {
		let fillColor = 'lightsteelblue';

		let strokeColor = fillColor;
		let zIndex = 1;

		if (isSelected) {
			fillColor = 'green';
			strokeColor = 'green';

			zIndex = 3;
		}

		if (isHovered) {
			fillColor = 'grey';
			strokeColor = 'grey';

			zIndex = 4;
		}

		if (isSelected && isHovered) {
			fillColor = 'green';
			strokeColor = 'greenyellow';
		}

		if (isIntersected) {
			fillColor = 'orange';
			strokeColor = 'orange';

			zIndex = 5;
		}

		return { fillColor, strokeColor, zIndex };
	}, [isHovered, isIntersected, isSelected]);

	// ! render
	if (visMap) {
		return (
			<VisPolygon
				key={`polygon-${area?.id}`}
				clickable={clickable}
				path={area?.polygon?.coordinates}
				visible={isVisible}
				{...colorOptions}
				{...restProps}
			/>
		);
	}
	return (
		<Polygon
			key={`polygon-${area?.id}`}
			path={area?.polygon?.coordinates}
			options={{ ...colorOptions, clickable }}
			visible={isVisible}
			{...restProps}
		/>
	);
};

export default AreaPolygon;
